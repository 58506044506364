import { useEffect, useMemo, useState } from "react";
import {
  Box,
  LoadingOverlay,
  Button,
  TextInput,
  Checkbox,
  Anchor,
  Tooltip,
  MultiSelect,
  Modal,
  Grid,
  Group,
  Badge,
  Text,
  Table,
} from "@mantine/core";
import { useSelector } from "react-redux";
import { ApiLink } from "../../Constant/ApiLink";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { MantineReactTable } from "mantine-react-table";
import { IconLink } from "@tabler/icons-react";
import Swal from "sweetalert2";

// Helper function to generate ordinal numbers for decile headers
const getOrdinal = (num) => {
  if (num === 1) return `${num}st`;
  if (num === 2) return `${num}nd`;
  if (num === 3) return `${num}rd`;
  return `${num}th`;
};

const MeasureAtlas = () => {
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );

  const [loading, setLoading] = useState(false);
  // All measure data from the API
  const [measureData, setMeasureData] = useState([]);
  // Filtered data displayed in the table
  const [filteredData, setFilteredData] = useState([]);
  // Specialties list from the API and user's selected specialties
  const [specialties, setSpecialties] = useState([]);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  // Checkbox/Notes state per measure (using measureId as unique key)
  const [selectedRecords, setSelectedRecords] = useState({});
  // Modal (popup) state for "View" details
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedMeasure, setSelectedMeasure] = useState(null);
  // Modal state for selected count details (list of measure IDs)
  const [selectedCountModalOpen, setSelectedCountModalOpen] = useState(false);

  // When measureData loads, initialize selectedRecords using each measure's "status" and "notes"
  useEffect(() => {
    if (measureData.length > 0 && Object.keys(selectedRecords).length === 0) {
      const initialRecords = {};
      measureData.forEach((measure) => {
        initialRecords[measure.measureId] = {
          checked: measure.status, // Using the "status" field
          notes: measure.notes || "", // Using the "notes" field
        };
      });
      setSelectedRecords(initialRecords);
    }
  }, [measureData]);

  // New effect: Pre-select the dropdown values from the first row's specialitesList (if exists)
  useEffect(() => {
    if (measureData.length > 0 && measureData[0].specialitesList) {
      const specs = measureData[0].specialitesList.split(",");
      setSelectedSpecialties(specs);
    }
  }, [measureData]);

  useEffect(() => {

    // Clear previous data
  setMeasureData([]);
  setFilteredData([]);
  setSelectedRecords({});
  setSelectedSpecialties([]);  // Clear the dropdown selections
  // Now fetch new data

    getMeasureAtlasSpecialtiesData();
    getMeasureAtlasData();
  }, [reportSelection]);

  // Whenever measureData, selectedSpecialties, or selectedRecords change, filter & sort the table data
  useEffect(() => {
    filterAndSortMeasures();
  }, [measureData, selectedSpecialties, selectedRecords]);

  const getMeasureAtlasSpecialtiesData = () => {
    if (selectedGroupId) {
      setLoading(true);
      ApiCall.axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}${ApiLink.Get_MeasureAtlasSubmission}/${reportSelection.selectedYear}`
        )
        .then((response) => {
          setSpecialties(response.data);
        })
        .catch((error) => {
          console.error("Error fetching specialties:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getMeasureAtlasData = () => {
    if (selectedGroupId) {
      setLoading(true);
      ApiCall.axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}${ApiLink.Get_MeasureAtlas}/${selectedGroupId}/${reportSelection.clinicId}/${reportSelection.selectedYear}`
        )
        .then((response) => {
          setMeasureData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching measure data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Filter measures based on selected specialties, then sort so that checked rows appear on top
  const filterAndSortMeasures = () => {
    let filtered;
    if (selectedSpecialties.length === 0) {
      filtered = [...measureData];
    } else {
      filtered = measureData.filter((item) => {
        const sets = item.measureSets || [];
        return sets.some((set) => selectedSpecialties.includes(set));
      });
    }
    // Sort: if a record is checked, bring it to the top
    filtered.sort((a, b) => {
      const aChecked = selectedRecords[a.measureId]?.checked;
      const bChecked = selectedRecords[b.measureId]?.checked;
      if (aChecked && !bChecked) return -1;
      if (!aChecked && bChecked) return 1;
      return 0;
    });
    setFilteredData(filtered);
  };

  // Update state using measureId as key
  const handleCheckboxChange = (e, measure) => {
    const { checked } = e.currentTarget;
    setSelectedRecords((prev) => ({
      ...prev,
      [measure.measureId]: {
        ...prev[measure.measureId],
        checked,
        notes: prev[measure.measureId]?.notes || "",
      },
    }));

    Swal.fire({
      title: "Measure",
      text: checked
        ? `${measure.measureId} Checked`
        : `${measure.measureId} Unchecked`,
      icon: checked ? "success" : "error",
      timer: 1100,
      showConfirmButton: false,
    });
  };

  const handleNotesChange = (e, measure) => {
    const { value } = e.currentTarget;
    setSelectedRecords((prev) => ({
      ...prev,
      [measure.measureId]: {
        ...prev[measure.measureId],
        notes: value,
        checked: prev[measure.measureId]?.checked || false,
      },
    }));
  };

  // Build payload with measureId, checkStatus, notes, group, clinicId, year, and specialties (as a string)
  const handleSave = () => {
    const selectedMeasures = measureData.filter(
      (measure) => selectedRecords[measure.measureId]?.checked
    );
    const payload = selectedMeasures.map((measure) => ({
      measureId: measure.measureId,
      status: selectedRecords[measure.measureId]?.checked,
      notes: selectedRecords[measure.measureId]?.notes,
      groupId: selectedGroupId,
      clinicId: reportSelection.clinicId,
      year: reportSelection.selectedYear,
      specalities: selectedSpecialties.join(","),
    }));
    console.log("Payload to save:", payload);

    setLoading(true);
    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.Post_MeasureAtlasSubmission}`,
        payload
      )
      .then((response) => {
        // Process response as needed
          Swal.fire({
                  icon: "success",
                  title: "Record Saved Successfully!",
                  showConfirmButton: false,
                  timer: 1500,
                });
      })
      .catch((error) => {
        console.error("Error posting measure data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // "View" button handler in the table
  const handleView = (measure) => {
    setSelectedMeasure(measure);
    setViewModalOpen(true);
  };

  // Compute count of selected records
  const selectedCount = Object.values(selectedRecords).filter(
    (rec) => rec.checked
  ).length;

  // Define columns for MantineReactTable
  const columns = useMemo(
    () => [
      {
        header: "View",
        id: "view",
        size: 60,
        Cell: ({ row }) => (
          <Button
            variant="light"
            size="xs"
            onClick={() => handleView(row.original)}
          >
            View
          </Button>
        ),
      },
      {
        accessorKey: "measureId",
        header: "Measure ID",
        size: 70,
      },
      {
        accessorKey: "title",
        header: "Title",
        Cell: ({ row }) => (
          <Tooltip label={row.original.title} withArrow>
            <div
              style={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {row.original.title}
            </div>
          </Tooltip>
        ),
      },
      {
        accessorKey: "eMeasureId",
        header: "E-Measure ID",
        size: 80,
      },
      // {
      //   accessorKey: "category",
      //   header: "Category",
      //   size: 80,
      // },
      // {
      //   accessorKey: "metricType",
      //   header: "Metric Type",
      // },
      // {
      //   accessorKey: "isHighPriority",
      //   header: "Is High Priority",
      //   size: 80,
      //   Cell: ({ row }) => (row.original.isHighPriority ? "Yes" : "No"),
      // },
      {
        header: "Measure Potential",
        id: "benchmarksSummary",
        size: 150,
        Cell: ({ row }) => {
          const benchmarks = row.original.benchmarks;
          if (!benchmarks || benchmarks.length === 0) return null;
      
         
          const abbreviationMapping = {
            ElectronicHealthRecord: "EHR",
            // If you need other mappings, add here
          };
      
          return (
            <div>
              {benchmarks.map((bm) => {
                // Use the mapping if available, otherwise use the original value
                const submissionMethod = abbreviationMapping[bm.submissionMethod] || bm.submissionMethod;
                return (
                  <Badge
                    key={bm.id}
                    style={{
                      fontSize: "10px",
                      marginRight: "4px",
                      marginBottom: "4px",
                    }}
                    variant="filled"
                  >
                    {submissionMethod}: {bm.measurePotential}
                  </Badge>
                );
              })}
            </div>
          );
        },
      },
      
      {
        id: "registry",
        header: "Registry",
        size: 80,
        accessorFn: (row) => row.measureSpecification?.registry,
        Cell: ({ row }) =>
          row.original.measureSpecification?.registry ? (
            <Anchor
              href={row.original.measureSpecification.registry}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconLink size={16} />
            </Anchor>
          ) : null,
      },
      {
        id: "ehr",
        header: "EHR",
        size: 80,
        accessorFn: (row) => row.measureSpecification?.electronicHealthRecord,
        Cell: ({ row }) =>
          row.original.measureSpecification?.electronicHealthRecord ? (
            <Anchor
              href={row.original.measureSpecification.electronicHealthRecord}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconLink size={16} />
            </Anchor>
          ) : null,
      },
      {
        header: "Selected",
        id: "selected",
        size: 50,
        Cell: ({ row }) => (
          <Checkbox
            checked={selectedRecords[row.original.measureId]?.checked || false}
            onChange={(e) => handleCheckboxChange(e, row.original)}
          />
        ),
      },
      {
        header: "Notes",
        id: "notes",
        size: 500,
        Cell: ({ row }) => (
          <TextInput
            style={{ width: "100%" }}
            placeholder="Notes"
            value={selectedRecords[row.original.measureId]?.notes || ""}
            onChange={(e) => handleNotesChange(e, row.original)}
            size="xs"
          />
        ),
      },
    ],
    [selectedRecords]
  );

  // Compute selected measure IDs for the selected count modal
  const selectedMeasureIds = measureData
    .filter((measure) => selectedRecords[measure.measureId]?.checked)
    .map((measure) => measure.measureId);

  return (
    <Box style={{ marginTop: "12px" }}>
      <div className="mycontainer">
        Measure Atlas
      </div>

      <LoadingOverlay visible={loading} overlayOpacity={0.3} />

      {/* Grid row for the specialties dropdown and the clickable selected count */}
      <Grid align="center" justify="space-between" mb={20} mt={20}>
        <Grid.Col span={8}>
          <MultiSelect
            data={specialties.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            value={selectedSpecialties}
            onChange={setSelectedSpecialties}
            placeholder="Select Specialties"
            label="Specialties"
            searchable
          />
        </Grid.Col>
        <Grid.Col span={2} style={{ textAlign: "right" }}>
          <Text style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => setSelectedCountModalOpen(true)}>
            Selected Count: {selectedCount}
          </Text>
        </Grid.Col>
      </Grid>

      {/* Show badges for specalitesList from the first row if available */}
      {/* {measureData.length > 0 && measureData[0].specialitesList && (
        <Group spacing="xs" mb={20}>
          {measureData[0].specialitesList.split(",").map((spec) => (
            <Badge key={spec} color="orange" variant="filled">
              {spec}
            </Badge>
          ))}
        </Group>
      )} */}

      <MantineReactTable
        columns={columns}
        data={filteredData}
        enableSorting
        enablePagination
        mantineTableProps={{
          highlightOnHover: true,
          withBorder: true,
          withColumnBorders: true,
          horizontalSpacing: 5,
          sx: {
            "thead tr th": {
              backgroundColor: "green",
              color: "white",
            },
          },
        }}
      />

      <Button onClick={handleSave} style={{ marginTop: "16px" }}>
        Save
      </Button>

      {/* Modal for "View" details */}
      <Modal
        opened={viewModalOpen}
        onClose={() => setViewModalOpen(false)}
        title="Measure Details"
        size="xxl"
      >
        {selectedMeasure && (
          <Grid gutter="md">
            <Grid.Col span={6}>
              <TextInput
                label="Measure ID"
                value={selectedMeasure.measureId || ""}
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="E-Measure ID"
                value={selectedMeasure.eMeasureId || ""}
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label="Title"
                value={selectedMeasure.title || ""}
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Metric Type"
                value={selectedMeasure.metricType || ""}
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Group mt="xl" spacing="xs">
                <Checkbox
                  label="Is High Priority"
                  checked={selectedMeasure.isHighPriority || false}
                  readOnly
                  styles={{
                    input: { cursor: "default" },
                    label: { cursor: "default" },
                  }}
                />
              </Group>
            </Grid.Col>
            <Grid.Col span={12}>
              <MultiSelect
                label="Submission Methods"
                data={selectedMeasure.submissionMethods || []}
                value={selectedMeasure.submissionMethods || []}
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Text weight={500} mb={4}>
                Measure Sets
              </Text>
              <Group spacing="xs">
                {(selectedMeasure.measureSets || []).map((set) => {
                  const isSelected = selectedSpecialties.includes(set);
                  return (
                    <Badge
                      key={set}
                      color={isSelected ? "green" : "gray"}
                      variant={isSelected ? "filled" : "light"}
                    >
                      {set}
                    </Badge>
                  );
                })}
              </Group>
            </Grid.Col>
            {/* Benchmarks Section */}
            {selectedMeasure.benchmarks && selectedMeasure.benchmarks.length > 0 && (
              <Grid.Col span={12}>
                <Text weight={500} mb={4}>
                  Benchmarks
                </Text>
                <Table withBorder withColumnBorders highlightOnHover  fontSize={12}>
                  <thead className="tableheader">
                    <tr>
                      <th>Submission Method</th>
                      <th>Low Outcome</th>
                      <th>Measure Potential</th>
                      {[...Array(10)].map((_, i) => (
                        <th key={i}>{`${getOrdinal(i + 1)} Decile`}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {selectedMeasure.benchmarks.map((bm) => (
                      <tr key={bm.id}>
                        <td>{bm.submissionMethod}</td>
                        <td>{bm.isToppedOut === true ? "Yes" : "No"}</td>
                        <td>{bm.measurePotential}</td>
                        {[...Array(10)].map((_, i) => {
                          const decileData = bm.percentilesses?.find(
                            (p) => p.decile === i + 1
                          );
                          return (
                            <td key={i}>
                              {decileData
                                ? `${decileData.from} - ${decileData.to}`
                                : ""}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Grid.Col>
            )}
          </Grid>
        )}
      </Modal>

      {/* Modal for showing selected measure IDs */}
      <Modal
        opened={selectedCountModalOpen}
        onClose={() => setSelectedCountModalOpen(false)}
        title="Selected Measure IDs"
        size="sm"
      >
        <Group spacing="xs" direction="column">
          {selectedMeasureIds.length ? (
            selectedMeasureIds.map((id) => (
              <Badge key={id} color="blue" variant="filled">
                {id}
              </Badge>
            ))
          ) : (
            <Text>No measures selected</Text>
          )}
        </Group>
      </Modal>
    </Box>
  );
};

export default MeasureAtlas;
