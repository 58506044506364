import { Paper } from "@mantine/core";
import { Tabs } from "@mantine/core";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { RouteLink } from "../Constant/RouterLink";
import { useSelector } from "react-redux";

export const EncounterTabs = () => {
  const linkStyle = {
    textDecoration: "none",
    color: "#000",
    fontSize: "15px",
    marginBottom: "0px",
    paddingBottom: "0px",
  };
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  if (location.pathname === RouteLink.Encounter_Uri) {
    navigate(RouteLink.Encounter_Upload_file);
  }

  const adminRouteWithIndex = [
    {text: "EncounterData", routeTo: RouteLink.Encounter_Uri_list, index: "1", level: 3},
    { text: "Cumalative Error Data", routeTo: RouteLink.Encounter_cumaltiveData_file, index: "2", level: 3 }, 
    { text: "Cumalative Raw Data", routeTo: RouteLink.Encounter_rawData_file, index: "3", level: 3 },
  ];

  const getActiveTabValue = () => {
    const path = location.pathname;
    if (path === RouteLink.Encounter_Uri) return "1";
   
  };

  const activeTabValue = getActiveTabValue();

  return (
    <Paper withBorder radius="md" p="md">
      <Tabs defaultValue={activeTabValue}>
        <Tabs.List>
          {adminRouteWithIndex.map((row) => {
            if (row.level >= user.level)
              return (
                <Link to={row.routeTo} style={linkStyle} key={row.index}>
                  <Tabs.Tab value={row.index} key={row.index}>
                    {row.text}
                  </Tabs.Tab>
                </Link>
              );
          })}

          {/* <Tabs.Tab value="13">Third tab</Tabs.Tab> */}
        </Tabs.List>

        <Outlet></Outlet>
      </Tabs>
    </Paper>
  );
};
