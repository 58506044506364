import { IconDashboard, IconHome } from "@tabler/icons-react";
import { RouteLink } from "./Constant/RouterLink";
import { useSelector } from "react-redux";

// Function to generate navLinks
export const useNavLinks = () => {
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const { user } = useSelector(state => state.auth);
   // Determine the appropriate clinicId or "all"
   const clinicIdOrAll = user.level === 3 && reportSelection ? reportSelection.clinicId : "all";

  //  alert(reportSelection.clinicId)
  return [
    { label: "Dashboard", icon: IconDashboard, link: "/user/dashboard", level: 3 },
    { label: "Admin", icon: IconHome, link: "/user/admin", level: 3 },
    { label: "Financial", icon: IconHome, link: "/user/financial", level: 3 },
    { label: "Tin summary", icon: IconHome, link: "/user/tin-Summary", level: 2 },
    { label: "Measure Atlas", icon: IconHome, link: "/user/measure-atlas", level: 0 },
    { label: "Quality", icon: IconHome, link: RouteLink.CSV_Upload_List, level: 3 },
    { label: "CCM", icon: IconHome, link: RouteLink.CCM, level: 3 },
    { label: "CPIA", icon: IconHome, link: "/user/cpia", level: 3 },
    { label: "PI", icon: IconHome, link: "/user/pi", level: 3 },
    { label: "Data", icon: IconHome, link: "/data/physician/list", level: 0 },
    {
      label: "Action Items",
      icon: IconHome,
      link: `/user/action-item/List/${clinicIdOrAll}`,
      level: 3,
    },
    { label: "Submission", icon: IconHome, link: "/user/Submission", level: 3 },
    { label: "Utilities", icon: IconHome, link: "/user/utilities/gui/list", level: 0 },
    { label: "EncounterData", icon: IconHome, link: "/user/EncounterData", level: 3 },
  ];
};
