import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Anchor, Box, Button, Grid, Group, Select, Switch } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useDisclosure } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import { RouteLink } from "../../Constant/RouterLink";
import { ApiLink } from "../../Constant/ApiLink";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { IconFile, IconTrash } from "@tabler/icons-react";
import { IconRefresh } from "@tabler/icons-react";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";


const EncounterData = () => {
  
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [category, setCategory] = useState("Quality");
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
  const [checked, setChecked] = useState(true);


  useEffect(() => {
    if (checked) {
      getCSVdataList(selectedGroupId)
    } else {
      getCSVdataList('0')
    }

  }, [checked, selectedGroupId, reportSelection.clinicId, category]);


 
  const getCSVdataList = (selectedGroupId) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.MeasureCSVUpload}/${selectedGroupId}/${reportSelection.clinicId}/encounter`
      )
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const columnDef = useMemo(() => [
    {
      header: "SubmissionType",
      accessorKey: "submissionType",
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Created Date",
      accessorFn: (row) => {
        let sDay = new Date(row.createdDate);
        var day = sDay.getDate();
        var month = sDay.getMonth() + 1; // Month is zero-based, so we add 1
        var year = sDay.getFullYear();
        sDay =
          (month < 10 ? "0" : "") +
          month +
          "-" +
          (day < 10 ? "0" : "") +
          day +
          "-" +
          year;
        return sDay;
      },
    },
    {
      header: "Guid",
      accessorKey: "randomFileId",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Inserted Records",
      accessorKey: "rCount",
    },
    {
      header: "Recived Records",
      accessorKey: "rInsertRecord",
    },
    {
      header: "File Id",
      accessorKey: "errormessage",
    },
    {
      header: "Year",
      accessorKey: "year",
    },
    {
      header: "File Download",
      accessorKey: "filePath",
      accessorFn: (row) => {
        return <Anchor target="_blank" onClick={() => handleDownloadAzure(row.filePath)}>
          {row?.filePath && <IconFile />}
        </Anchor>
      },
    }
    // {
    //   header: "Log File",
    //   accessorFn: (row) => {
    //     return row?.logData && row.logData.length > 0 ? (
    //       <Anchor onClick={() => handleDownload(row.logData)}>
    //         <IconFile />
    //       </Anchor>
    //     ) : null;
    //   },
    //}


  ]);

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: "LogFile"
  });

  const handleDownload = (logData) => {
    try {
      // Format the data for CSV
      const formattedData = logData.map((item) => {
        let errorData = {};
        try {
          errorData = JSON.parse(item.errorData);
        } catch (parseError) {
          console.error(`Error parsing JSON for item: ${item.errorData}`, parseError);
        }
        return {
          ...errorData,
          errorMessage: item.errorMessage,
        };
      });
      try {
        const csv = generateCsv(csvConfig)(formattedData);
        download(csvConfig)(csv);

      } catch (csvError) {
        console.error('Error generating or downloading CSV:', csvError);
      }
    } catch (error) {
      console.error('An error occurred during the download process:', error);
    }
  };

  const handleDownloadAzure = async (filepath) => {
    try {
    
      const fileName = filepath.split('/').pop();
      // API URL to download the file
        let apiUrl = "";
        apiUrl = `${process.env.REACT_APP_API_URL}${ApiLink.QualityDownload}/${fileName}?IA=En`;
      

      // Make the API call using axios
      const response = await ApiCall.axiosInstance.get(apiUrl, {
        responseType: 'blob', // Important to get the file as a blob
      });

      // Create a download link and click it to start the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName; // Set the download filename
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);


    } catch (error) {
      console.error('An error occurred during the download process:', error);
    }
  };


  const onCreate = () => {
    navigate(RouteLink.Encounter_Upload_file, {
      state: { data: null, method: "post" },
    });
  };
  

  

  const [deletingRow, setDeletingRow] = useState({});
  const onDelete = (value) => {

    setDeletingRow(value);
    open();
  };

  const [opened, { close, open }] = useDisclosure(false);

  const [formLoading, setLoading] = useState(false);

  function IsConfirmed(value) {
    if (value === true) {
      setLoading(true);
      ApiCall.axiosInstance["delete"](
        `${process.env.REACT_APP_API_URL}${ApiLink.MeasureCSVUpload}?uuidfile=${deletingRow.randomFileId}&category=${'Encounter'}`
      )
        .then((response) => {
          setLoading(false);
          setRowData((oldValues) => {
            return oldValues.filter((rowData) => rowData.id !== deletingRow.id);
          });
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    }
  }

  const handleRefresh = () => {
    getCSVdataList(selectedGroupId);
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal
        IsConfirmed={IsConfirmed}
        opened={opened}
        open={open}
        close={close}
      />
      <Grid>
        <Grid.Col span={2}>
          <Group position="apart" /* or "center", "right", etc. */>
            <Button type="primary" onClick={() => onCreate()}>
              Upload New File
            </Button>
            <Button type="primary" onClick={handleRefresh}>
              <IconRefresh />
            </Button>
          </Group>

        </Grid.Col>

        {/* <Grid.Col span={2}>

          <Select
            value={category}
            onChange={setCategory}
            data={[
              { label: "Quality", value: "Quality" },
              { label: "IA", value: "IA" },
              { label: "PI", value: "PI" },
            ]}
          />
        </Grid.Col> */}
        <Grid.Col span={5}></Grid.Col>
        <Grid.Col span={3}>
          <Switch style={{ float: 'right' }}
            label='Show Group wise TIN'
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
          />
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={columnDef}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn="first"
        renderRowActions={(row) => (
          <RowAction onUpdate={() => onDelete(row.row.original)} />
        )}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default EncounterData;

const RowAction = (props) => {
  return (
    <Grid>
      <Button.Group>
        {
          <Button
            variant="filled"
            color="red"
            size="xs"
            onClick={props.onUpdate}
          >
            <IconTrash size="1.2rem"></IconTrash>
          </Button>
        }
      </Button.Group>
    </Grid>
  );
};
