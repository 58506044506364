import { Badge } from "@mantine/core";
import { useMemo } from "react";

const RequirementColumn =
    [


        {
            header: " E-measure Id",
            accessorKey: "eMeasureId",
        },
        {
            header: "Measure Id",
            accessorKey: "measureId",
        },
        {
            header: "Tittle",
            accessorKey: "title",
        },
        {
            header: "Category",
            accessorKey: "category",
        },
        {
            header: "Metric Type",
            accessorKey: "metricType",
        },
          {
                header: "Measure Potential",
                id: "benchmarksSummary",
                size: 150, 
                Cell: ({ row }) => {
                  const benchmarks = row.original.benchmarks;
                  if (!benchmarks || benchmarks.length === 0) return null;
              
                 
                  const abbreviationMapping = {
                    ElectronicHealthRecord: "EHR",
                    // If you need other mappings, add here
                  };
              
                  return (
                    <div>
                      {benchmarks.map((bm) => {
                        // Use the mapping if available, otherwise use the original value
                        const submissionMethod = abbreviationMapping[bm.submissionMethod] || bm.submissionMethod;
                        return (
                          <Badge
                            key={bm.id}
                            style={{
                              fontSize: "10px",
                              marginRight: "4px",
                              marginBottom: "4px",
                            }}
                            variant="filled"
                          >
                            {submissionMethod}: {bm.measurePotential}
                          </Badge>
                        );
                      })}
                    </div>
                  );
                },
              },





    ];
export default RequirementColumn;