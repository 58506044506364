import { ApiLink } from "../../Constant/ApiLink";
import NpiModel from "./NpiModel";
import PecosDataGrid from "./PecosGrid";
import {
  TextInput,
  Button,
  Box,
  Grid,
  Textarea,
  Select,
  Divider,
  Text,
  LoadingOverlay,
  Table,
} from "@mantine/core";
import { Loader } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RouteLink } from "../../Constant/RouterLink";
import AttestationHistory from "./AttestationHistory";
import { useDispatch, useSelector } from "react-redux";
import PhysicinQppGrid from "./PhysicianQppGrid";
import { nFormatter } from "../../Shared/NumberFunctions";
import ManualPecosModel from "./ManualPecosModel";
import { refreshTreeData, setDisableYear } from "../../utils/patenerTreeSlice";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

function PhysicianForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const { data, method } = useLocation().state;
  const [data] = useState(
    JSON.parse(localStorage.getItem("physicianData"))?.data
  );
  const [method] = useState(
    JSON.parse(localStorage.getItem("physicianData"))?.method
  );

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [records, setRecords] = useState([]);

  const [firsTName, setFirstName] = useState("");
  const [lasTName, setLastName] = useState("");
  const [Group, setGroup] = useState([]);
  const [entitytype, setentitytype] = useState([]);
  const [isAttestation, setisAttestation] = useState(false);
  const [reqSet, setReqSet] = useState([]);
  const [physicianQppData, setPhysicianQppData] = useState([]);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );
  const SelectedClinicList = useSelector(
    (store) => store.partenerTree.selectedGroupClnics
  );

  // for Mannual pecors
  const [pecosClncData, setPecosClncData] = useState([]);
  const [pecosManualData, setPecosManualData] = useState([]);

  const [isMannualPecosOpen, setIsMannualPecosOpen] = useState(false);
  const [recordsMannualPecos, setRecordsMannualPecos] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [totalAttestedClinicCount, setTotalAttestClinicCount] = useState(0);
  const [isShowClinicGrid, setIsShowClinicGrid] = useState(true);
  const [combinedClincManualData, setCombinedClinicManualData] = useState([]);

  //from initial
  const form = useForm({
    initialValues: {
      id: data?.id,
      firstName: data?.firstName,
      lastName: data?.lastName,
      npi: data?.npi,
      orgnpi: data?.orgnpi ?? "",
      phone: data?.phone,
      email: data?.email,
      enrollId: data?.enrollmentId,
      comment: data?.comment,
      acccountType: data?.acccountType ?? true,
      speciality: data?.speciality ?? "",
      providerType: data?.providerType ?? "",
      totalclinic: data?.totalclinic,
      attestedClinic: data?.attestedClinic,
      hireDate:
        data?.hireDate === "0001-01-01T00:00:00" || !data?.hireDate
          ? new Date()
          : new Date(data?.hireDate),
      // group: {
      //   id: data?.group.id ? data?.group.id : null,
      //   name: data?.group.name,
      // },
      entitytype: {
        id: data?.entitytype?.id ? data?.entitytype?.id : 0,
        name: data?.entitytype?.name,
      },
      PecosClinic: [],
      isOnline: false,
    },

    // functions will be used to validate values at corresponding key
    validate: {
      firstName: (value) =>
        !value || value === "" ? "First Name is required!" : null,
      lastName: (value) =>
        !value || value === "" ? "Last Name is required!" : null,
      npi: (value) => (!value || value === "" ? "NPI is required!" : null),
      //orgnpi: (value) => (!value || value === "" ? "Organization NPI is required!" : null),
      enrollId: (value) =>
        !value || value === "" ? "Enrollment Id is required!" : null,
      phone: (value) =>
        !value || value === "" ? "Phone no is required!" : null,
      totalclinic: (value) =>
        value == 0 ? "Total Clinic can't be zero!" : null,
      attestedClinic: (value) =>
        totalAttestedClinicCount == 0
          ? "Total Attested Clinic can't be zero!"
          : null,
      // email: (value) => (!value || value === "" ? "Email is required!" : null),
      acccountType: (value) =>
        value.toString() == "" ? "AccountType is required!" : null,
      hireDate: (value) =>
        !value || value === "" ? "Hire Data is required!" : null,
      // group: {
      //   id: (value) =>
      //   !value || value === "" ? "Group is required!" : null,
      // },
      // entitytype: {
      //   id: (value) =>
      //   !value || value === "" ? "Entity Type is required!" : null,
      // },
      // PecosClinic: (value) => (!value[0]?.enrollmentId || value[0]?.enrollmentId === "" ? alert("Pecos clinic Data is required!") : null),
      // PecosClinic: {
      //   enrollmentId: (value) =>
      //     !value || value === "" ? "Pecos Clinic is required!" : null,
      //   orgName: (value) =>
      //     !value || value === "" ? "Organization Name is required!" : null,

      //   isAttested: (value) =>
      //     !value || value === "" ? "is Organization attested. required!" : null,
      // },
    },
  });

  useEffect(() => {
    if (data !== null) {
      setisAttestation(true);
      setFirstName(data?.firstName);
      setLastName(data?.lastName);
      setPecosClncData(data?.pecosClinic);
      getPhysicianQpp(data?.enrollmentId);
    }
    dispatch(setDisableYear(true));
    return () => {
      dispatch(setDisableYear(false));
      localStorage.removeItem("physicianData");
    };
  }, [data]);
  //show ateestation div base on conditions

  useEffect(() => {
    if (data != null) {
      setLoading(true);
      ApiCall.axiosInstance
        .get(
          `${process.env.REACT_APP_API_URL}${ApiLink.getPhysicianByEnrollmentId}/${data?.id}/${reportSelection.selectedYear}`
        )
        .then((response) => {
          if (response?.data?.length) {
            setCombinedClinicManualData(response?.data[0].pecosClinic);
          } else {
            setCombinedClinicManualData([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    }
  }, [reportSelection.selectedYear]);

  useEffect(() => {
    if (method == "post") {
      setCombinedClinicManualData([]);
      setCombinedClinicManualData([...pecosManualData, ...pecosClncData]);
    } else if (method == "put" && data != null) {
      const allCombinedData = Array.from([
        ...combinedClincManualData,
        ...pecosManualData,
        ...pecosClncData,
      ]);
      const uniquePacIds = new Set(allCombinedData.map((item) => item.pacId));
      const uniqueCombinedData = Array.from(uniquePacIds).map((pacId) => {
        return allCombinedData.find((item) => item.pacId === pacId);
      });
      setCombinedClinicManualData(uniqueCombinedData);
    }
    setIsShowClinicGrid(false);
    setLoading(true);
    setTimeout(() => {
      setIsShowClinicGrid(true);
      setLoading(false);
    }, 20);
    setTimeout(() => {
      getClinicCount();
    }, 20);
  }, [pecosManualData, pecosClncData]);

  const getClinicCount = () => {
    let count = 0;
    pecosManualData?.forEach((ele) => {
      if (ele.isAttested == true) {
        count++;
      }
    });
    pecosClncData?.forEach((ele) => {
      if (ele.isAttested == true) {
        count++;
      }
    });
    setTotalAttestClinicCount(count);
  
  };

  useEffect(() => {
    if (selectedRecord) {
      setFirstName(selectedRecord?.firsT_NAME);
      setLastName(selectedRecord?.lasT_NAME);
      form.setValues({
        firstName: selectedRecord?.firsT_NAME,
        lastName: selectedRecord?.lasT_NAME,
        npi: selectedRecord?.npi,
        enrollId: selectedRecord?.enrlmT_ID,
      });
      if (selectedRecord?.enrlmT_ID) {
        getMoreInformationaboutOrg(selectedRecord?.enrlmT_ID);
      } else if (headerText == "(NPEES)") {
        getMoreInformationOnNPEES(selectedRecord?.npi);
      }
      getTotalClinicCount(selectedRecord?.npi);
    }
  }, [selectedRecord]);
  //----------------------------

  const getPhysicianQpp = (enrollmentId) => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.getPhysiciansQpp}/${enrollmentId}?year=${reportSelection.selectedYear}`
      )
      .then((response) => {
        setPhysicianQppData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("get NPI records Fails:", error);
        setLoading(false);
      });
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    const npiParam = {
      firstName: firsTName,
      lastName: lasTName,
    };

    setLoading(true);
    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.GetPhysicianByName}`,
        npiParam
      )
      .then((response) => {
        
        setLoading(false);
        if (response?.data?.length) {
          setHeaderText("(Data.gov)");
          setRecords(response?.data);
          setIsModalOpen(true);
        }
      })
      .catch((error) => {
        console.error("get NPI records Fails:", error);
        setLoading(false);
      });
  };

  const getDataFromNPEES = (e) => {
    setHeaderText("(NPEES)");
    e.preventDefault();
    const npiParam = {
      firstName: firsTName,
      lastName: lasTName,
    };

    setLoading(true);
    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}/${ApiLink.GetNpiByName}`,
        npiParam
      )
      .then((response) => {
        setRecords(response?.data);
        setIsModalOpen(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Errow while getting Data:", error);
        setLoading(false);
      });
  };

  const getTotalClinicCount = (npiId) => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.GetQppDataFromQppApiBasedOnNPIandYear}/${npiId}/${reportSelection.selectedYear}`
      )
      .then((response) => {
        form.setValues({
          totalclinic: response?.data?.data?.organizations?.length,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("get Clinic Count record Fails:", error);
        setLoading(false);
      });
  };

  const getMoreInformationaboutOrg = (enrollmentid) => {
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.Clinic_Uri_ClinicDetailsByEnrollmentId}/${enrollmentid}`
      )
      .then((response) => {
       
        const records = response.data;

        form.setValues({
          speciality: records.results[0].taxonomies.find(
            (x) => x.primary === true
          ).desc,
          //speciality: records.results[0].taxonomies[0].desc,
          providerType: String(records.results[0].basic.credential),
          phone: records.results[0].addresses[0].telephone_number,
        });
      })
      .catch((error) => {
       
      });
  };

  const getMoreInformationOnNPEES = (npiId) => {
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.GetClinicDetailsByNPI}/${npiId}`
      )
      .then((response) => {
        
        const records = response.data;
        form.setValues({
          speciality: records.results[0].taxonomies.find(
            (x) => x.primary === true
          ).desc,
          //speciality: records.results[0].taxonomies[0].desc,
          providerType: String(records.results[0].basic.credential),
          phone: records.results[0].addresses[0].telephone_number,
        });
      })
      .catch((error) => {
        console.error("get NPI records Fails:", error);
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseModalPecos = () => {
    setIsMannualPecosOpen(false);
  };

  const handleSelectRecord = (record) => {
    setSelectedRecord(record);
  };

  const handleSelectRecordPecos = (record) => {
    let count = record?.length + pecosClncData?.length;
    setPecosManualData(record);
    // setTimeout(() => {
    //   form.setValues({ totalclinic:count});
    // }, 200);
  };

  const getPecosClinic = (e) => {
    e.preventDefault();
    setLoading(true);
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        // `https://macamonitorapi.azurewebsites.net/v1/Physicians/getpecosclinic/${selectedRecord.enrlmT_ID}`
        `${process.env.REACT_APP_API_URL}${ApiLink.Physician_GetPecosClinic}/${selectedRecord.npi}/${selectedGroupId}`
      )
      .then((response) => {
        
        setPecosClncData(response.data);
        // form.setValues({ totalclinic: response.data.length });
        setLoading(false);
      })
      .catch((error) => {
       
        setLoading(false);
      });

    getPhysicianQpp(selectedRecord.enrlmT_ID);
  };

  const getMannualpecosclinic = () => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.GetMannualpecosclinic}/${selectedGroupId}`
      )
      .then((response) => {
        
        setLoading(false);
        // let prevPecosData = pecosClncData;
        // prevPecosData.push(...response.data)
        // setPecosClncData([]);
        // setPecosClncData(prevPecosData);
        
        // debugger;

        setRecordsMannualPecos(response?.data);
        setIsMannualPecosOpen(true);
      })
      .catch((error) => {
        console.error("get pecos clinic records Fails:", error);
        setLoading(false);
      });

    //getPhysicianQpp(selectedRecord.enrlmT_ID)
  };



  const handleSubmit = (formValues) => {
    combinedClincManualData.forEach((obj) => {
      obj.groupId = parseInt(obj.groupId, 10);
    });
    formValues["PecosClinic"] = [];
    formValues["PecosClinic"] = combinedClincManualData;
    formValues["attestedClinic"] = totalAttestedClinicCount;
    formValues["year"] = reportSelection.selectedYear;
    

    setLoading(true);
   

    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.Physician_Uri}`,
      formValues
    )
      .then((response) => {
       
        setLoading(false);
        dispatch(refreshTreeData());
        navigate(RouteLink.Physician_Uri);
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        if (error?.response?.status == 409) {
          alert("NPI already Exists");
        } else if (error?.response?.status == 410) {
          alert("Enrollmentid already Exists");
        } else if (error?.response?.status == 400) {
          alert("Record already exist");
        }
        setLoading(false);
      });
  };

  const handleBack = () => {
    navigate(RouteLink.Physician_Uri);
    setLoading(false);
  };

  //bind  Group
  useEffect(() => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.Group_Uri}`)
      .then((response) => {
        setGroup(response.data);
        
      })
      .catch((error) => {
        
      });
  }, []);
  //bind  Group

  //bind  Group
  useEffect(() => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.Entity_Type}`)
      .then((response) => {
        setentitytype(response.data);
       
      })
      .catch((error) => {
        console.error("Error Entity fetching data:", error);
      });
  }, []);
  //bind  Group

  //ReqSet
  useEffect(() => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.ReqSet_Uri}`)
      .then((response) => {
        // Extract "reqId" and "title" fields from the JSON data and store them in the state.
        const extractedData = response.data.map((item) => ({
          reqId: item.reqId,
          title: item.title,
        }));

        setReqSet(extractedData);
        
      })
      .catch((error) => {
        
      });
  }, []);
  //ReqSet

  function setEntityTypeByGroupSlect(groupId, pacId) {
    const filterByGroup = Group.filter((row) => row.id == groupId)[0];
    if (filterByGroup) {
      const entityTypeId = filterByGroup.entityTypeId;
      
      // if (entityTypeId != undefined)
      //   form.getInputProps("entitytype.id").onChange(entityTypeId);
      

      setCombinedClinicManualData((prevData) =>
        prevData.map((item) =>
          item.pacId === pacId ? { ...item, ["entityId"]: entityTypeId } : item
        )
      );
    }
  }

  function groupChange(value) {
    setEntityTypeByGroupSlect(value);
    form.getInputProps("group.id").onChange(value);
  }

  const customLabelStyle = {
    display: "inline-block",
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#212529",
    wordBreak: "break-word",
    cursor: "default",
  };

  const generateRandomIds = () => {
    const min = Math.pow(10, 13);
    const max = Math.pow(10, 14) - 1;
    const id = "P" + Math.floor(Math.random() * (max - min + 1)) + min;

    form.setValues({
      enrollId: id.toString().slice(0, 15),
    });
  };

  const generateRandomIdNPI = () => {
    const min = Math.pow(10, 13);
    const max = Math.pow(10, 14) - 1;
    const id = "N" + Math.floor(Math.random() * (max - min + 1)) + min;

    form.setValues({
      npi: id.toString().slice(0, 10),
    });
  };

  const getSetFirstName = (Fname) => {
    setFirstName(Fname);
    form.setValues({ firstName: Fname });
  };

  const getSetLastName = (Lname) => {
    setLastName(Lname);
    form.setValues({ lastName: Lname });
  };

  const getCheckedClinicCount = (count) => {
    setTotalAttestClinicCount(count);
  };

  const openNewWindow = () => {
    // Calculate the center position for the popup
    const url = `https://qpp.cms.gov/participation-lookup?npi=${form.values.npi}&py=${reportSelection.selectedYear}`;
    const width = 1000;
    const height = 700;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const windowName = "qppWindow";
    // Open the popup window
    window.open(
      url,
      windowName,
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  useEffect(() => {
    let count = 0;
    combinedClincManualData?.forEach((ele) => {
      if (ele.isAttested == true) {
        count += 1;
      }
    });
    setTotalAttestClinicCount(count);
  }, [combinedClincManualData]);

  const handleCheckboxChange = (event, pacId, property) => {
    const { checked } = event.target;
    setCombinedClinicManualData((prevData) =>
      prevData.map((item) =>
        item.pacId === pacId ? { ...item, [property]: checked } : item
      )
    );
  };

  const handleDropDownChange = (event, pacId, property) => {
    const { value } = event.target;
    setCombinedClinicManualData((prevData) =>
      prevData.map((item) =>
        item.pacId === pacId ? { ...item, [property]: value } : item
      )
    );
    if (property === "groupId") setEntityTypeByGroupSlect(value, pacId);
  };

  const headStyle = { color: "white", fontWeight: "500" };

  return (
    <Box>
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}
      <h5>Physician: Add New</h5>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={3}>
            <TextInput
              label="First Name: "
              placeholder="Name"
              {...form.getInputProps("firstName")}
              value={firsTName}
              onChange={(event) => getSetFirstName(event.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              label="Last Name: "
              placeholder="Name"
              {...form.getInputProps("lastName")}
              value={lasTName}
              onChange={(event) => getSetLastName(event.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              label="Provider Type"
              placeholder="Provider Type"
              {...form.getInputProps("providerType")}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Button
              mx={5}
              style={{ marginTop: "25px", float: "right" }}
              onClick={getDataFromNPEES}
            >
              Get NPEES
            </Button>

            <Button
              style={{ marginTop: "25px", float: "right" }}
              onClick={handleOpenModal}
            >
              Get NPI
            </Button>

            {isModalOpen && (
              <NpiModel
                records={records}
                header={headerText}
                onClose={handleCloseModal}
                onSelect={handleSelectRecord}
              />
            )}
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={4}>
            <TextInput
              label="NPI: "
              placeholder="NPI"
              {...form.getInputProps("npi")}
              readOnly={method == "put"}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Button
              disabled={method == "put"}
              onClick={generateRandomIdNPI}
              mt={25}
            >
              Gen NPI Id
            </Button>
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              label="Enrollment ID:"
              placeholder="Enrollment ID"
              {...form.getInputProps("enrollId")}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Button
              disabled={method == "put"}
              onClick={generateRandomIds}
              mt={25}
            >
              Gen Enrollment Id
            </Button>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={3}>
            <TextInput
              label="Speciality"
              placeholder="Speciality"
              {...form.getInputProps("speciality")}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              label="Total Clinic"
              placeholder="Total Clinic"
              {...form.getInputProps("totalclinic")}
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Button disabled={!form.values.npi} onClick={openNewWindow} mt={25}>
              QPP Query
            </Button>
          </Grid.Col>
          {/* Model code for pecos Clinic */}
          {isMannualPecosOpen && (
            <ManualPecosModel
              records={recordsMannualPecos}
              onClose={handleCloseModalPecos}
              onSelect={handleSelectRecordPecos}
            />
          )}
          <Grid.Col span={2}>
            <Button
              style={{ marginTop: "25px" }}
              onClick={getMannualpecosclinic}
            >
              Manually Assign MM clinic
            </Button>
          </Grid.Col>

          <Grid.Col span={2}>
            <Button
              disabled={selectedRecord === null}
              style={{ marginTop: "25px", float: "right" }}
              onClick={getPecosClinic}
            >
              Lookup Clinic
            </Button>
          </Grid.Col>
        </Grid>
        {method == "put" && (
          <Box>
            <Divider
              my="xs"
              size="sm"
              label="Physician Qpp Data"
              labelPosition="center"
              style={{ marginTop: "2rem", marginBottom: "1rem" }}
              color="green"
            />
            <Grid>
              <Grid.Col span={4}>
                <Text style={customLabelStyle}>TIN Counter</Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text style={customLabelStyle}>NPI Total Amount</Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text style={customLabelStyle}>Per Clinic Amount</Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={4}>
                <Text>
                  {physicianQppData?.qppClinicCount == 0 ||
                  !physicianQppData?.qppClinicCount
                    ? "Not Available"
                    : physicianQppData?.qppClinicCount}
                </Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text>{nFormatter(physicianQppData?.medicareAmount, 2)}</Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text>
                  {physicianQppData?.qppClinicCount &&
                  physicianQppData?.qppClinicCount > 0
                    ? nFormatter(
                        physicianQppData?.medicareAmount /
                          physicianQppData?.qppClinicCount,
                        2
                      )
                    : "Not Available"}
                </Text>
              </Grid.Col>
            </Grid>
            <Divider
              my="xs"
              size="sm"
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
              color="green"
            />
          </Box>
        )}
        <Grid style={{ marginTop: "1rem" }}>
          {combinedClincManualData?.length > 0 &&
          isShowClinicGrid &&
          !loading ? (
            <Grid.Col span={12}>
              <Table
                striped
                highlightOnHover
                withBorder
                withColumnBorders
                sx={{ overflowX: "auto" }}
                mb={10}
              >
                <thead style={{ backgroundColor: "#3d8a3d" }}>
                  <tr>
                    <th style={headStyle}>Organization Name</th>
                    <th style={headStyle}>Pac Id</th>
                    <th style={headStyle}>Attested Status</th>
                    <th style={headStyle}>Group</th>
                    <th style={headStyle}>Entity Type</th>
                  </tr>
                </thead>
                <tbody>
                  {combinedClincManualData?.map((item) => (
                    <tr key={item.pacId}>
                      <td>{item?.orgName}</td>
                      <td>{item?.pacId}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={item?.isAttested}
                          onChange={(e) =>
                            handleCheckboxChange(e, item.pacId, "isAttested")
                          }
                          className="chkbox"
                        />
                        {}
                      </td>
                      <td>
                        <select
                          value={item.groupId}
                          disabled={item.ismannual || item.isAttested}
                          onChange={(e) =>
                            handleDropDownChange(e, item.pacId, "groupId")
                          }
                          className="ddldropdown"
                        >
                          {Group.map((ele) => (
                            <option key={ele.id} value={ele.id}>
                              {ele.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td style={{ minWidth: "10rem" }}>
                        <select
                          disabled={item.ismannual || item.isAttested}
                          value={item.entityId}
                          onChange={(e) =>
                            handleDropDownChange(e, item.pacId, "entityId")
                          }
                          className="ddldropdown"
                        >
                          {entitytype.map((ele) => (
                            <option key={ele.id} value={ele.id}>
                              {ele.name}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Grid.Col>
          ) : (
            ""
          )}
          {loading && (
            <LoadingOverlay
              visible={loading}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            />
          )}
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="Organization NPI: "
              placeholder="Organization NPI"
              {...form.getInputProps("orgnpi")}
            />
          </Grid.Col>
          {/* <Grid.Col span={3}>
            <Select
              label="Group"
              withAsterisk
              searchable
              nothingFound="No options"
              clearable
              {...form.getInputProps("group.id")}
              onChange={groupChange}
              data={Group.map(ele => ({
                value: ele.id,
                label: ele.name,
              }))}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Select
              label="Entity Type"
              withAsterisk
              searchable
              nothingFound="No options"
              clearable
              {...form.getInputProps("entitytype.id")}
              data={entitytype.map(ele => ({
                value: ele.id,
                label: ele.name,
              }))}
            />
          </Grid.Col> */}
          <Grid.Col span={6}>
            <TextInput
              label="Attested Clinic"
              placeholder="Attested Clinic"
              {...form.getInputProps("attestedClinic")}
              value={totalAttestedClinicCount}
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="Phone:"
              placeholder="Phone No."
              {...form.getInputProps("phone")}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Email: "
              placeholder="Email"
              {...form.getInputProps("email")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={12}>
            <Textarea
              label="Comment"
              placeholder="Your comment"
              {...form.getInputProps("comment")}
            />
          </Grid.Col>
        </Grid>

        <Divider
          my="xs"
          size="sm"
          label="Status"
          labelPosition="center"
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
          color="green"
        />

        <Grid>
          <Grid.Col span={6}>
            <Select
              label="Account Type: "
              placeholder="Account Type"
              {...form.getInputProps("acccountType")}
              data={[
                { value: true, label: "Active" },
                { value: false, label: "Deactive" },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DateInput
              dateParser={(input) => {
                if (input === "WW2") {
                  return new Date(1939, 8, 1);
                }
                return new Date(input);
              }}
              valueFormat="MM/DD/YYYY"
              label="Date input"
              placeholder="Date input"
              {...form.getInputProps("hireDate")}
            />
          </Grid.Col>
        </Grid>

        <br></br>

        {/* //Attestation */}
        {isAttestation && data ? (
          <AttestationHistory
            phyEnrollId={data?.id}
            pecos={data?.pecosClinic}
            year={reportSelection.selectedYear}
          />
        ) : null}
        {/* //Attestation */}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Button onClick={() => handleBack()}>Back</Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default PhysicianForm;
